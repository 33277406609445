.panel-theme .MuiTableCell-head {
  cursor: pointer;
}

.panel-theme .MuiTableCell-root {
  font-size: 0.75rem !important;
  padding: 12px !important;
}

.panel-theme a {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 600px) {
  .panel-theme .MuiContainer-root {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

/*Custom shadows*/
.panel-theme .MuiPaper-elevation1 {
  /*border-radius: 20px !important;*/
  /*box-shadow:rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px !important;*/
}

/*Custom border radiuis*/
.panel-theme .MuiOutlinedInput-root {
  border-radius: 8px !important;
}

/*Custom table*/
.panel-theme .MuiTableCell-root {
  font-size: 0.75rem !important;
  padding: 12px !important;
}

.panel-theme .MuiTableHead-root {
  background-color: rgb(244, 246, 248);
}

.panel-theme .MuiTableHead-root th {
  color: rgb(99, 115, 129) !important;
  border: none;
}

.panel-theme .MuiTableCell-head {
  cursor: pointer;
  font-size: 0.83rem !important;
  padding-top: 23px !important;
  padding-bottom: 22px !important;
}

.panel-theme .MuiTableCell-head:first-child {
  padding-left: 24px !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: rgb(255 255 255) 8px 0px 0px inset;
}

.panel-theme .MuiTableCell-head:last-child {
  padding-right: 24px !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: rgb(255 255 255) -8px 0px 0px inset;
}

.panel-theme .MuiTableBody-root tr td {
  /*border: none !important;*/
}

.panel-theme .MuiTableBody-root tr td:first-of-type {
  padding-left: 24px !important;
}

.panel-theme .MuiTableBody-root tr td:last-of-type {
  padding-right: 24px !important;
}

.panel-theme .MuiTableRow-root:hover {
  background-color: rgba(145, 158, 171, 0.08);
}

/**/

.panel-theme .MuiButton-containedPrimary {
  border-radius: 8px !important;
}

.panel-theme .MuiPaper-rounded {
  border-radius: 16px !important;
}

.panel-theme .MuiToggleButton-root {
  border-radius: 8px !important;
}

.panel-theme .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.panel-theme .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
