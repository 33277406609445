.sticky {
    position: sticky;
    top:-5px;
    background-color: white;
    z-index: 9;
}

.postLanguage .ql-editor{
    min-height:600px;
}

.postLanguage .btnLang{
    min-width: 60px;
}

.postLanguage .btnLang.selected{
    background-color: #f10a4d;
    color: white;
}